<template>
  <div class="container">
    <transition name="fadein">
      <presentation-info v-show="!this.$store.state.presentationStarted" />
    </transition>
    <div
      v-if="
        browsingActive && (((activeChapter === 0 && activeSlide > 0) || activeChapter > 0))
      " id="prev"
      @click="prevSlide">
      &lt;
    </div>
    <div
      v-if="
        browsingActive && ((activeSlide < chapters[activeChapter].slides.length - 1 && activeChapter > 0) || activeChapter < chapters.length - 1)
      "
      id="next"
      @click="nextSlide"
    >
      &gt;
    </div>
    <template v-for="(chapter, chapterIndex) in chapters">
      <div
        v-for="(slide, slideIndex) in chapter.slides"
        :key="slide.id"
        class="slide"
        v-bind:class="[
          { active: activeSlide === slideIndex && activeChapter === chapterIndex },
            { beforeActive: (slideIndex < activeSlide && chapterIndex <= activeChapter) || chapterIndex < activeChapter },
            { afterActive: (slideIndex > activeSlide && chapterIndex >= activeChapter) || chapterIndex > activeChapter},
        ]"
        :data-name="chapterIndex + '-' + slideIndex"
      >
        <div class="slide__inner">
          <img :src="slide.imageUrl" :alt="slide.description" />
          <div
            v-for="(popup, index) in slide.subslides"
            :key="index"
            :style="{ left: popup.x + '%', top: popup.y + '%' }"
            @click="handleSubslideOpen(index)"
            class="plus"
          >
            +
          </div>
          <div class="clickmarker"></div>
        </div>
      </div>
    </template>
    <!-- <div
      class="endslide"
      v-bind:class="[
        {
          active:
            activeChapter == chapters.length - 1 &&
            activeSlide === chapters[activeChapter].slides.length,
        },
        { afterActive: activeChapter < chapters.length - 1 },
        {
          afterActive:
            activeChapter === chapters.length - 1 &&
            activeSlide < chapters[activeChapter].slides.length,
        },
      ]"
    >
      <PresentationEndScreen />
    </div> -->
    <transition name="fadein">
      <div id="subslide__container" v-if="typeof openSubslide == 'number'">
        <div
          id="subslide__close"
          v-if="browsingActive"
          @click="handleSubslideClose"
        >
          &#10005;
        </div>

        <img
          :src="slides[activeSlide].subslides[openSubslide].imageUrl"
          alt=""
        />
      </div>
    </transition>
  </div>
</template>

<script>
// @ is an alias to /src
import PresentationInfo from "@/components/PresentationInfo.vue";
// import PresentationEndScreen from "@/components/PresentationEndScreen.vue";

export default {
  name: "Viewslide",
  components: {
    PresentationInfo,
    // PresentationEndScreen,
  },
  props: ["id"],
  data: function () {
    return {
      localSubslide: null,
    };
  },
  computed: {
    storeId() {
      return this.$store.state.presentationId;
    },
    chapters() {
      return this.$store.state.importedChapters;
    },
    activeChapter() {
      return this.$store.state.activeChapter;
    },
    activeSlide() {
      return this.$store.state.activeSlide;
    },
    browsingActive() {
      return this.$store.state.browsingActive;
    },
    endScreenActive() {
      return this.$store.state.endScreenActive;
    },
    subslides() {
      return this.chapters[this.activeChapter].slides[this.activeSlide]
        .subslides;
    },
    openSubslide() {
      if (
        this.$store.state.browsingActive &&
        typeof this.localSubslide == "number"
      ) {
        return this.localSubslide;
      }
      return this.$store.state.openSubslide;
    },
    subslideIsOpen() {
      if (typeof this.openSubslide === "number") {
        return true;
      } else {
        return false;
      }
    },
    clickMarker() {
      return this.$store.state.clickMarker;
    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    clickMarker(newMarker, oldMarker) {
      if (!((newMarker.X === oldMarker.X) && (newMarker.Y === oldMarker.Y))) {
        let slide = document.querySelector(".slide.active>.slide__inner");
        if (slide === null) {
          slide = document.querySelector(
            ".endslide.active>.endscreen__container"
          );
        }
        let clickMarker = slide.querySelector(".clickmarker");
        let newClickMarker = clickMarker.cloneNode(true);
        newClickMarker.style.left = newMarker.X + "%";
        newClickMarker.style.top = newMarker.Y + "%";
        slide.replaceChild(newClickMarker, clickMarker);
        newClickMarker.classList.add("animating");
      }
    },
    endScreenActive(newBrowsing, oldBrowsing) {
      console.log('new: ' + newBrowsing + ' Old: ' + oldBrowsing)
      if (newBrowsing == true) {
        this.$router.push({
          name: "Kontakt",
          params: { id: this.$store.state.presentationId },
        });
      }
    },
  },
  methods: {
    prevSlide() {
      this.$store.dispatch("PREVIOUSSLIDE");
      setTimeout(() => {
        this.handleFooterPadding();
      }, 200);
    },
    nextSlide() {
      this.$store.dispatch("NEXTSLIDE");
      setTimeout(() => {
        this.handleFooterPadding();
      }, 200);
    },
    handleSubslideOpen(index) {
      this.localSubslide = index;
    },
    handleSubslideClose() {
      this.localSubslide = null;
    },
    handleFooterPadding() {
      let slide = document.querySelector(".container > .active > div");
      let footer = document.getElementById("footer__logos");
      if (slide.innerWidth == 0) {
        footer.style.marginLeft = "10px";
      }
      footer.style.marginLeft =
        slide.offsetLeft > 10 ? slide.offsetLeft + "px" : "10px";
    },
  },
  created() {
    window.addEventListener("resize", this.handleFooterPadding);
    
  },
  mounted() {
    if (!(this.id && this.storeId && this.id == this.storeId)) {
      console.log("Store id not matching document id, reloading data");
      this.$store.dispatch("GETDATAFROMFIREBASE", this.id);
    }
    setTimeout(() => this.handleFooterPadding(), 2000);
    },
  destroyed() {
    window.removeEventListener("resize", this.handleFooterPadding);
  },
};
</script>
<style>
#next,
#prev {
  position: absolute;
  display: flex;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: white;
  background-color: rgba(0, 0, 0, 0.1);
  transition: background-color 300ms ease-in-out;
  border-radius: 100px;
  z-index: 100;
  user-select: none;
}
#next:hover,
#prev:hover {
  background-color: var(--navbar);
}
#prev {
  left: 4%;
  top: calc(50% - 20px);
}
#next {
  right: 4%;
  top: calc(50% - 20px);
}

.beforeActive {
  transform: translateX(-100vw);
}

.afterActive {
  transform: translateX(100vw);
}
.container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.slide {
  transition: all 300ms ease-in-out;
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
.endslide {
  transition: all 300ms ease-in-out;
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.slide__inner {
  position: relative;
  /* flex: 1 1 100%; */
}
.slide img {
  max-width: 100vw;
  max-height: calc(100vh - var(--navbarheight) - var(--footerheight));
  width: auto;
  height: auto;
  display: block;
}
.slide .title {
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  background-color: rgb(36, 36, 58);
  padding: 0.2rem 0.6rem;
}
.slide .description {
  position: absolute;
  top: 2.2rem;
  left: 0.5rem;
  background-color: rgb(36, 36, 58);
  padding: 0.2rem 0.6rem;
  font-size: 0.8rem;
}
.plus {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  font-size: 24px;
  width: 30px;
  height: 30px;
  background: #ffffff33;
  color: orange;
  border: 2px solid orange;
  border-radius: 30px;
  transition: background 300ms ease-in-out;
  cursor: pointer;
  transform-origin: center center;
  transform: translate3d(-15px, -15px, 0);
  font-weight: 900;
  user-select: none;
}
.plus:hover {
  background: #ffffffff;
}
.plus:hover::before {
  animation: none;
}
.plus::before {
  z-index: -10;
  display: block;
  position: absolute;
  border: 2px solid orange;
  content: "";
  border-radius: 50px;
  animation: pulse 3s infinite;
  width: 100%;
  height: 100%;
}
.warning {
  position: absolute;
  top: 1rem;
  background-color: red;
  color: white;
  padding: 3rem;
}
.welcome__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  background-color: white;
}
#subslide__container {
  padding: 3%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100vw;
  max-height: calc(100vh - var(--navbarheight) - var(--footerheight));
}
#subslide__close {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 3rem;
  height: 3rem;
  background-color: rgba(255, 255, 255, 0.5);
  border: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
#subslide__container img {
  max-width: 100%;
  max-height: 100%;
}
.fadein-enter-active,
.fadein-leave-active {
  transition: opacity 0.5s;
}
.fadein-enter, .fadein-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.logos {
  position: absolute;
  left: 1rem;
  bottom: 1rem;
}
.clickmarker {
  position: absolute;
  width: 10px;
  height: 10px;
  transform: translateX(-50%) translateY(-50%);
  border: 2px solid orange;
  z-index: 500;
  border-radius: 100%;
  opacity: 0;
}
.clickmarker.animating {
  animation: scaleup 1s ease-out forwards;
}

@keyframes pulse {
  0% {
    background: #ffffff00;
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
  50% {
    background: #ffffff66;
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
  80% {
    background: #ffffffff;
    opacity: 0;
    transform: scale3d(1.5, 1.5, 1.5);
  }
  100% {
    background: #ffffff00;
    opacity: 0;
    transform: scale3d(1, 1, 1);
  }
}
@keyframes scaleup {
  0% {
    transform: translateX(-50%) translateY(-50%) scale(1);
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) scale(8);
    opacity: 0;
  }
}
</style>
