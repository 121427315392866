<template>
  <div class="info__container">
    <div class="split__container">
      <div class="left">
        <h1>Innehåll</h1>
        <ol>
          <!-- TODO Fix dynamic version of chapters -->
          <li v-for="chapter in chapters" :key="chapter.id">
            {{ chapter.chapterTitle }}
          </li>
        </ol>
      </div>
      <div class="right">
        <img
          :src="presenter.avatar"
          :alt="presenter.firstName + ' ' + presenter.lastName"
          class="presenter__image"
        />
        <h2>{{ presenter.firstName }} {{ presenter.lastName }}</h2>
        <h3>{{ presenter.title }}</h3>
        <div class="separator"></div>
        <p><strong>Mobil: </strong> {{ presenter.phone }}</p>
        <p>
          <strong>Mail: </strong>
          <a :href="'mailto:' + presenter.eMail">{{ presenter.eMail }}</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PresentationInfo",
  computed: {
    presenter() {
      return this.$store.state.presenter;
    },
    chapters() {
      return this.$store.state.importedChapters;
    },
  },
};
</script>

<style lang="scss">
.info__container {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  h1 {
    border-bottom: 2px solid var(--black);
    margin-top: 0;
  }
  h2 {
    margin-bottom: 0;
  }
  h3 {
    margin: 0;
  }
  p {
    margin: 0;
  }

  .separator {
    display: block;
    height: 2px;
    background-color: var(--black);
    width: 100%;
    margin: 0.5rem 0;
  }
  img {
    width: 100%;
    display: block;
  }

  .split__container {
    display: flex;
    width: 60rem;
    & > div {
      flex: 0 1 50%;
      text-align: left;
      margin: 1rem;
      &.right {
        flex: 0 1 auto;
      }
    }
  }
  .presenter__image {
    max-width: 300px;
  }
}
</style>