var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('transition',{attrs:{"name":"fadein"}},[_c('presentation-info',{directives:[{name:"show",rawName:"v-show",value:(!this.$store.state.presentationStarted),expression:"!this.$store.state.presentationStarted"}]})],1),(
      _vm.browsingActive && (((_vm.activeChapter === 0 && _vm.activeSlide > 0) || _vm.activeChapter > 0))
    )?_c('div',{attrs:{"id":"prev"},on:{"click":_vm.prevSlide}},[_vm._v(" < ")]):_vm._e(),(
      _vm.browsingActive && ((_vm.activeSlide < _vm.chapters[_vm.activeChapter].slides.length - 1 && _vm.activeChapter > 0) || _vm.activeChapter < _vm.chapters.length - 1)
    )?_c('div',{attrs:{"id":"next"},on:{"click":_vm.nextSlide}},[_vm._v(" > ")]):_vm._e(),_vm._l((_vm.chapters),function(chapter,chapterIndex){return _vm._l((chapter.slides),function(slide,slideIndex){return _c('div',{key:slide.id,staticClass:"slide",class:[
        { active: _vm.activeSlide === slideIndex && _vm.activeChapter === chapterIndex },
          { beforeActive: (slideIndex < _vm.activeSlide && chapterIndex <= _vm.activeChapter) || chapterIndex < _vm.activeChapter },
          { afterActive: (slideIndex > _vm.activeSlide && chapterIndex >= _vm.activeChapter) || chapterIndex > _vm.activeChapter} ],attrs:{"data-name":chapterIndex + '-' + slideIndex}},[_c('div',{staticClass:"slide__inner"},[_c('img',{attrs:{"src":slide.imageUrl,"alt":slide.description}}),_vm._l((slide.subslides),function(popup,index){return _c('div',{key:index,staticClass:"plus",style:({ left: popup.x + '%', top: popup.y + '%' }),on:{"click":function($event){return _vm.handleSubslideOpen(index)}}},[_vm._v(" + ")])}),_c('div',{staticClass:"clickmarker"})],2)])})}),_c('transition',{attrs:{"name":"fadein"}},[(typeof _vm.openSubslide == 'number')?_c('div',{attrs:{"id":"subslide__container"}},[(_vm.browsingActive)?_c('div',{attrs:{"id":"subslide__close"},on:{"click":_vm.handleSubslideClose}},[_vm._v(" ✕ ")]):_vm._e(),_c('img',{attrs:{"src":_vm.slides[_vm.activeSlide].subslides[_vm.openSubslide].imageUrl,"alt":""}})]):_vm._e()])],2)}
var staticRenderFns = []

export { render, staticRenderFns }